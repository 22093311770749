var chatOptions = new Map();
var socket;

function Connector() {
    if (arguments.callee._singletonInstance)
        return arguments.callee._singletonInstance;
    arguments.callee._singletonInstance = this;

    this.pingTimer = "";
    return true;
}

Connector.prototype.connect = function () {
    socket = io(chatOptions.get("socket_url"));
    socket.on('connect', function () {
        Connector().enterVisitor();
    });
    socket.on("visitorApp", function (data) {
        Connector().onData(data);
    });

};

Connector.prototype.closeConnection = function() {
    if (socket) {
        socket.close();
    }
}

Connector.prototype.enterVisitor = function () {
    stopVideo();
    chatOptions.set('errorCode','');
    Connector().send({
        ACTION: 'RP_ENTER_VISITOR',
        CAMMER_ACCOUNT: chatOptions.get('service'),
        CHAT_MODE: chatOptions.get('chatType'),
        NICKNAME: chatOptions.get('nickname'),
        VISITOR_HASH: chatOptions.get('visitor_hash'),
    });
};

Connector.prototype.sendPublicText = function (text) {
    Connector().send({
        ACTION: 'SEND_TEXT',
        ROOM_TYPE: 'PUBLIC',
        TEXT: text
    });
};


Connector.prototype.sendPrivateText = function (text) {
    Connector().send({
        ACTION: 'SEND_TEXT',
        ROOM_TYPE: 'PRIVATE',
        TEXT: text
    });
};

Connector.prototype.enterFree = function () {
    chatOptions.set("chatType", "FREE");
    Connector().enterVisitor();
};

Connector.prototype.enterGroup = function () {
    chatOptions.set("chatType", "ONE2MANY");
    Connector().enterVisitor();
};

Connector.prototype.enterPrivate = function () {
    chatOptions.set("chatType", "PRIVATE");
    Connector().enterVisitor();
};

Connector.prototype.sendPingDelayed = function () {
    clearTimeout(Connector().pingTimer);
    Connector().pingTimer = setTimeout(Connector().sendPingNow, chatOptions.get("ping_interval"));
};

Connector.prototype.sendPingNow = function () {
    Connector().send({ ACTION: 'RP_PING' });
};

Connector.prototype.pingResult = function (result) {
    if (result.get('SUCCESS') === 'Y') {
        if (result.get('TARIFF') !== chatOptions.get('currentTariff')) {
            chatOptions.set('currentTariff', result.get('TARIFF'));
            chatOptions.get('updateHandler')('update_cam_model');
        }
        Connector().sendPingDelayed();
    } else {
        stopVideo();
        chatOptions.get('errorHandler')('pingFailed', result.get('ERROR_CODE'));
    }
};

Connector.prototype.enterVisitorResult = function (result) {
    if (result.get('SUCCESS') === 'Y') {
        var streaminfo = { JSMPEG_SERVER: result.get('JSMPEG') };
        chatOptions.set('currentTariff', result.get('TARIFF'));
        // chatOptions.set('cammerChatType', result.get('CAMMER_CHAT_TYPE'));

        // chatOptions.get('chatInfoUpdatedFunction')();
        Connector().sendPingDelayed();
        if (chatOptions.get("startVideo") === true) {
            startVideo(streaminfo);
        }
    }
    else {
        chatOptions.get('errorHandler')('enterVisitorResult', result.get('ERROR_CODE'));
    }
};

Connector.prototype.textMessageReceived = function (response) {
    chatOptions.get("textMessageReceivedFunction")(response);
};

Connector.prototype.send = function (data) {
    socket.emit("visitorApp", data);
};

Connector.prototype.roomInfoReceived = function (data) {
    if (data.get('NUMBER_OF_VISITORS')) {
        chatOptions.get('updateHandler')('visitor_count', data.get('NUMBER_OF_VISITORS') - 1);
    }
};

Connector.prototype.updateOne2OneStatus = function(data) {
    // if (data.get('OBJECT_TYPE') === 'chatroom')
    if (data.get('ONE2ONE_STATUS') === 'ONE2ONE_VOYEUR') {
        chatOptions.get('updateHandler')('going_private');
    } else if (data.get('ONE2ONE_STATUS') !== 'ONE2ONE_ACTIVE') {
        chatOptions.get('updateHandler')('private_stopped');
    }
};

Connector.prototype.privateUpdated = function (data) {
    if (data.get("STATUS") === 'ACCEPTED') {
        chatOptions.get('updateHandler')('private_started');
    }
}

Connector.prototype.handleCammerUpdate = function (data) {

    if (data.get("ACCOUNT") === chatOptions.get('service')) {
        let eventMessage;

        switch (data.get('EVENT')) {
            case 'UPDATE_ONE2ONE_TARIFF':
            case 'UPDATE_VOYEUR_TARIFF':
            case 'UPDATE_DEFAULT_TARIFF':
                eventMessage = 'update_cam_model';
                break;
            case 'UPDATE_STATUS':
                if (data.get('NEW_VALUE') === 'OFFLINE') {
                    eventMessage = 'offline';
                }
                break;
            case 'UPDATE_CHAT_TYPE':
                if (data.get('OLD_VALUE') === 'O2M' && data.get('NEW_VALUE') === 'O2O') {
                    eventMessage = 'force_next_event';
                }
                break;
        }

        if (eventMessage) {
            chatOptions.get('updateHandler')(eventMessage);
        }
    }
}

Connector.prototype.handleCloseReason = function (data) {
    if (data.get('REASON') === 'CAMMER_TO_PRIVATE' && data.get('VOYEUR_ALLOWED') === 'N') {
        chatOptions.get('updateHandler')('offline');
    } else {
        chatOptions.get('errorHandler')('closeReason', data.get("REASON"));
    }
}

Connector.prototype.onData = function (content) {
    if (content) {
        var strInput = content.split('\n');

        while (strInput.length > 0) {
            var line = strInput.shift();
            if (line != "") {
                var response = new Map(Object.entries(JSON.parse(line)))
                var action = response.get('ACTION');
                switch (action) {
                    case 'RP_ENTER_VISITOR': Connector().enterVisitorResult(response); break;
                    case 'RP_PING': Connector().pingResult(response); break;
                    case 'TEXT': Connector().textMessageReceived(response); break;
                    case 'CHAT_CONTROL_chatroom_changeOne2oneStatus': Connector().updateOne2OneStatus(response); break;
                    case 'ROOM_INFO': Connector().roomInfoReceived(response); break;
                    case 'PRIVATE_UPDATE': Connector().privateUpdated(response); break;
                    case 'CAMMER_UPDATE': Connector().handleCammerUpdate(response); break;
                    case 'CLOSE_REASON': Connector().handleCloseReason(response); break;
                }
            }
        }
    }
}


