var janus;
var streaming;
var jsmpegPlayer;

function startVideo(streamInfo) {
    chatOptions.set("streamInfo", streamInfo)
    if (chatOptions.get('use_rtc') == true) {
        startRtcVideo();
    } else {
        fallbackToJspmegVideo();
    }
}

function fallbackToJspmegVideo() {
    console.log('FALLBACK JSPEG !!!');
    $("#" + chatOptions.get('VIDEO_ID')).html("<canvas width='600' id='jsmpeg-video'></canvas>");
    startJsmpegVideo();
}

function stopVideo() {
    if (typeof janus !== 'undefined' && janus != null) {
        janus.destroy({asyncRequest: false});
        delete (janus);
    }
    if (typeof jsmpegPlayer !== 'undefined') {
        jsmpegPlayer.stop();
        delete (jsmpegPlayer);
    }
}

function startRtcVideo() {
    // $("#" + chatOptions.get('VIDEO_ID')).html("<video class='w-100' id='rtc-video' playsinline autoplay></video>");
    Janus.init({
        debug: false,
        callback: function () {
            if (!Janus.isWebrtcSupported()) {
                janusErrorHandler("NO_RTC_SUPPORT");
            } else {
                startJanus();
            }
        }
    });
}

function startJanus() {
    var info = {"account": chatOptions.get("service"), "action": 'play', "visitorHash": chatOptions.get("visitor_hash"), codec: "h264"}

    var server = chatOptions.get("janus_url") + "/janus/" + btoa(JSON.stringify(info));
    janus = new Janus({
        server: server,
        success: janusSessionSuccess,
        error: function (error, a) {
            janusErrorHandler(error)
        },
        destroyed: function () {}
    });
}

function janusSessionSuccess() {
    var opaqueId = "streamingtest-" + Janus.randomString(12);
    janus.attach({
        plugin: "janus.plugin.streaming", opaqueId: opaqueId,
        success: function (pluginHandle) {
            streaming = pluginHandle;
            var body = {"request": "watch", id: chatOptions.get("service")};
            streaming.send({
                "message": body,
                error: function (error) {
                    janusErrorHandler(error)
                }
            });
        },
        error: function (error) {
            janusErrorHandler(error);
        },
        onmessage: function (msg, jsep) {
            var result = msg["result"];
            if (result !== null && result !== undefined) {
                if (result["status"] !== undefined && result["status"] !== null) {
                    var status = result["status"];
                    if (status === 'starting') {
                    } else if (status == 'started') {
                    } else if (status == 'stopped') {
                        if (streaming) {
                            setTimeout(streaming.hangup, 1);
                        }
                    }
                }
            } else if (msg["error"] !== undefined && msg["error"] !== null) {
                janusErrorHandler(msg["error"]["reason"]);
                return;
            }
            if (jsep !== undefined && jsep !== null) {
                streaming.createAnswer({
                    jsep: jsep,
                    media: {audioSend: false, videoSend: false, data: true},       // We want recvonly audio/video
                    success: function (jsep) {
                        if (jsep.sdp) {
                            var body = {"request": "start"};
                            streaming.send({
                                "message": body, "jsep": jsep, error: function (error) {
                                    janusErrorHandler(error)
                                }
                            });
                        }
                    },
                    error: function (error) {
                        janusErrorHandler(error);
                    }
                })
            }
        },
        onremotestream: function (stream) {
            Janus.attachMediaStream($('#rtc-video').get(0), stream);
        },
        oncleanup: function () {
        },
    });
}

function janusErrorHandler(error) {
    if (!janus.isConnected()) {
        return;
    }

    janus.destroy({asyncRequest: true});
    startRtcVideo();
}

function startJsmpegVideo() {
    var streamInfo = chatOptions.get('streamInfo');
    var canvas = document.getElementById('jsmpeg-video');
    jsmpegPlayer = new JSMpeg.Player(streamInfo['JSMPEG_SERVER'], { canvas: canvas });
}
